let Message = undefined;

module.exports = function submit(author, email, title, msg) {
    if (Message === undefined) {
        AV.init('YKkn0nodgkFDKWX9CjFrH2H2-gzGzoHsz', 'dCTpFBbK8Kj1wvQlIOYozpfT');
        Message = AV.Object.extend('Messages');
    } 

    const message = new Message();
    message.set('author', author);
    message.set('email', email);
    message.set('title', title);
    message.set('message', msg);
    return message.save();
}