import './deps/jquery-min'
import './deps/popper.min'
import './deps/bootstrap.min'
import './deps/owl.carousel.min'
import './deps/jquery.nav'
import './deps/scrolling-nav'
import './deps/jquery.easing.min'
import './deps/jquery.slicknav'
import './deps/form-validator.min'
import submitMessage from './storage'

(function ($) {
  
  "use strict";
  
  $(window).on('load', function () {
    /*Page Loader active
    ========================================================*/
    $('#preloader').fadeOut();

    // Sticky Nav
    $(window).on('scroll', function () {
      if ($(window).scrollTop() > 50) {
        $('.scrolling-navbar').addClass('top-nav-collapse');
      } else {
        $('.scrolling-navbar').removeClass('top-nav-collapse');
      }
    });

    // one page navigation 
    $('.navbar-nav').onePageNav({
      currentClass: 'active'
    });

    /* slicknav mobile menu active  */
    $('.mobile-menu').slicknav({
      prependTo: '.navbar-header',
      parentTag: 'liner',
      allowParentLinks: true,
      duplicate: true,
      label: '',
      closedSymbol: '<i class="lni-chevron-right"></i>',
      openedSymbol: '<i class="lni-chevron-down"></i>',
    });

    /* Testimonials Carousel 
    ========================================================*/
    var owl = $("#testimonials");
    owl.owlCarousel({
      loop: true,
      nav: false,
      dots: false,
      center: true,
      margin: 15,
      slideSpeed: 1000,
      stopOnHover: true,
      autoPlay: true,
      responsiveClass: true,
      responsiveRefreshRate: true,
      responsive: {
        0: {
          items: 1
        },
        768: {
          items: 1
        },
        960: {
          items: 1
        },
        1200: {
          items: 1
        },
        1920: {
          items: 1
        }
      }
    });

    /* Back Top Link active
    ========================================================*/
    var offset = 200;
    var duration = 500;
    $(window).scroll(function () {
      if ($(this).scrollTop() > offset) {
        $('.back-to-top').fadeIn(400);
      } else {
        $('.back-to-top').fadeOut(400);
      }
    });

    $('.back-to-top').on('click', function (event) {
      event.preventDefault();
      $('html, body').animate({
        scrollTop: 0
      }, 600);
      return false;
    });

    /* Map Form Toggle
    ========================================================*/
    $('.map-icon').on('click', function (e) {
      $('#conatiner-map').toggleClass('panel-show');
      e.preventDefault();
    });

    /* Contact Form
    ========================================================*/
    $("#contactForm").validator().on("submit", function (event) {
      if (event.isDefaultPrevented()) {
        // handle the invalid form...
        formError();
        submitMSG(false, "Did you fill in the form properly?");
      } else {
        // everything looks good!
        event.preventDefault();
        submitForm();
      }
    });

    function submitForm() {
      // Initiate Variables With Form Content
      var name = $("#name").val();
      var email = $("#email").val();
      var msg_subject = $("#msg_subject").val();
      var message = $("#message").val();

      submitMessage(name, email, msg_subject, message).then(() => {
        formSuccess();
      }, err => {
        formError();
        submitMSG(false, err);
      });
    }

    function formSuccess() {
      $("#contactForm")[0].reset();
      submitMSG(true, "Thank You! Message Submitted.")
    }

    function formError() {
      $("#contactForm").removeClass().addClass('shake animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function () {
        $(this).removeClass();
      });
    }

    function submitMSG(valid, msg) {
      if (valid) {
        var msgClasses = "h3 text-left tada animated text-success";
      } else {
        var msgClasses = "h3 text-left text-danger";
      }
      $("#msgSubmit").removeClass().addClass(msgClasses).text(msg);
    }

  });

}(jQuery));